const colors = {
  primary: {
    red: "#f76c6c",
    lightBlue: "#a8d0e6",
    midBlue: "#374785",
    darkBlue: "#24305e",
    yellow: "#f8e9a1",
  },
}

export default colors

const Text = {
  baseStyle: {
    color: "white",
    fontWeight: "500",
    fontFamily: "body",
    mb: "20px",
    fontSize: ["lg", "xl", "2xl"],
  },
}

export default Text